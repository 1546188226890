<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">PHP</h4>
            <div class="small text-muted">Código PHP para generar interfaces o tareas del Cron</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="4">
              <Widget :configWidget="configWidget" reference="cant_php_interfaces" :hasAccess="configWidget.elements.indicatorTotalInterface" />
              <Widget :configWidget="configWidget" reference="list_php_interfaces" :hasAccess="configWidget.elements.listInterface" />
            </b-col>
            <b-col md="4">               
              <Widget :configWidget="configWidget" reference="cant_php_active_cron" :hasAccess="configWidget.elements.indicatorTotalCronActive" />
              <Widget :configWidget="configWidget" reference="list_php_active_cron" :hasAccess="configWidget.elements.listCronActive" />
            </b-col>            
          </b-row>
        </b-col>  
        <b-col md="3">
          
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openInterfaces()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Interfaces</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Crear interfaces accesibles desde URL o widgets
              </p>
            </b-list-group-item>
          </b-list-group>             

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCron()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Cron</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Crear script para tareas programadas
              </p>
            </b-list-group-item>
          </b-list-group>   

        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>  
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.PHP,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.PHP,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalCronActive: true,
            listCronActive: true,
            indicatorTotalInterface: true,
            listInterface: true,
          }
        },
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalCronActive = Helper.hasAccessWidget(this.configWidget, 'cant_php_active_cron')      
      this.configWidget.elements.listCronActive = Helper.hasAccessWidget(this.configWidget, 'list_php_active_cron')      
      this.configWidget.elements.indicatorTotalInterface = Helper.hasAccessWidget(this.configWidget, 'cant_php_interfaces')      
      this.configWidget.elements.listInterface = Helper.hasAccessWidget(this.configWidget, 'list_php_interfaces')      
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      
      openInterfaces() {
        this.$router.push({ name: 'PHPStaffCrudPHPInterfaces' })
      },
      openCron() {
        this.$router.push({ name: 'PHPStaffCrudPHPCron' })
      },

    }    
   
  }
</script>

<style>

</style>
